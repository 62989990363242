export function currency(value?: number) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
    if (value) {
        return formatter.format(value)
    }
    return '-'
}
