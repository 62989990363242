import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import correctionService from "./correction.service";
import { ICorrectionState } from "../../../app/types/correction.type";
import {
  IBaseCorrection,
  IComment,
  ICriteriaValue,
} from "../../../app/interfaces";

export const getCorrection = createAsyncThunk(
  "corrections/get",
  async (correctionId: number) => {
    const correction = await correctionService.getCorrection(correctionId);
    return correction;
  }
);

export const finishCorrection = createAsyncThunk(
  "corrections/save",
  async (correction: IBaseCorrection) => {
    return await correctionService.saveCorrection({
      ...correction,
      finish: true,
    });
  }
);

export const getEssay = createAsyncThunk(
  "essays/get",
  async (essayId: number) => {
    return correctionService.getEssay(essayId);
  }
);

export const getCorrectionValidity = createAsyncThunk(
  "corrections/validity",
  async (examId: number) => {
    return correctionService.getCorrectionValidity(examId);
  }
);

export const getCorrectionCriteria = createAsyncThunk(
  "corrections/criteria",
  async (examId: number) => {
    return correctionService.getCorrectionCriteria(examId);
  }
);

export const addComment = createAsyncThunk(
  "corrections/comment/add",
  async (comment: IComment) => {
    return correctionService.addComment(comment);
  }
);

export const removeComment = createAsyncThunk(
  "corrections/comment/remove",
  async (comment: IComment) => {
    return correctionService.removeComment(comment);
  }
);

export const updateComment = createAsyncThunk(
  "corrections/comment/update",
  async (comment: IComment) => {
    return correctionService.updateComment(comment);
  }
);

export const getComments = createAsyncThunk(
  "corrections/comment/get",
  async (correctionId: number) => {
    return correctionService.getComments(correctionId);
  }
);

export const getTheme = createAsyncThunk(
  "esssay/theme/get",
  async (themeId: string) => {
    return correctionService.getTheme(themeId);
  }
);

export const getExam = createAsyncThunk("exam/get", async (examId: number) => {
  return correctionService.getExam(examId);
});

const initialState: ICorrectionState = {
  correction: { criteriaValues: [], rotation: 0, finalComment: "" },
  theme: { motivationalTitle: "", motivationalText: "", theme: "" },
  validity: [],
  criteria: [],
  comments: [],
  exam: {},

  finished: false,
  loading: false,
  error: null,
  onError: false,
  success: false,
};

const correctionSlice = createSlice({
  name: "correction",
  initialState,
  reducers: {
    setCriteriaValue: (state, action: PayloadAction<ICriteriaValue>) => {
      const ix = state.correction.criteriaValues.findIndex(
        (c) => c.criteriaId === action.payload.criteriaId
      );
      if (ix >= 0) {
        state.correction.criteriaValues[ix] = action.payload;
      } else {
        state.correction.criteriaValues.push(action.payload);
      }
    },
    setCorrectionValidity: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.correction.correctionValidityId = action.payload;

      const isValid = state.validity.find(
        (v) => v.id === action.payload
      )?.isValid;

      if (!isValid) {
        state.correction.criteriaValues = [];
      }
    },
    setFinalComment: (state, action: PayloadAction<string>) => {
      state.correction.finalComment = action.payload;
    },
    setRotation: (state, action: PayloadAction<number>) => {
      state.correction.rotation = action.payload;
    },
    reset: (state) => {
      state.essay = initialState.essay;
      state.correction = initialState.correction;
      state.comments = initialState.comments;
      state.finished = false;
      state.error = null;
      state.success = false;
      state.onError = false;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
      state.onError = false;
    },
    setFinished: (state) => {
      state.finished = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorrection.fulfilled, (state, { payload }) => {
      state.correction = payload;
    });
    builder.addCase(getCorrection.rejected, (state) => {
      state.correction = initialState.correction;
    });
    builder.addCase(finishCorrection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(finishCorrection.fulfilled, (state, { payload }) => {
      state.correction = payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(finishCorrection.rejected, (state) => {
      state.error = "Erro ao salvar a correção.";
      state.onError = true;
      state.loading = false;
    });
    builder.addCase(getEssay.fulfilled, (state, { payload }) => {
      state.essay = payload;
    });
    builder.addCase(getEssay.rejected, (state) => {
      state.error = "Erro ao buscar a redação.";
    });
    builder.addCase(getCorrectionValidity.fulfilled, (state, { payload }) => {
      state.validity = payload;
    });
    builder.addCase(getCorrectionValidity.rejected, (state) => {
      state.error = "Erro ao buscar validação.";
    });
    builder.addCase(getCorrectionCriteria.fulfilled, (state, { payload }) => {
      state.criteria = payload;
    });
    builder.addCase(getCorrectionCriteria.rejected, (state) => {
      state.error = "Erro ao buscar critérios de correçào.";
    });
    builder.addCase(addComment.fulfilled, (state, { payload }) => {
      state.comments.push(payload);
    });
    builder.addCase(addComment.rejected, (state, { payload }) => {
      state.error = "Erro ao salvar comentário.";
    });
    builder.addCase(getComments.fulfilled, (state, { payload }) => {
      state.comments = payload;
    });
    builder.addCase(getComments.rejected, (state) => {
      state.error = "Erro ao buscar comentários.";
    });
    builder.addCase(getTheme.fulfilled, (state, { payload }) => {
      state.theme = payload;
    });
    builder.addCase(getTheme.rejected, (state) => {
      state.error = "Erro ao buscar tema.";
    });
    builder.addCase(getExam.fulfilled, (state, { payload }) => {
      state.exam = payload;
    });
    builder.addCase(getExam.rejected, (state) => {
      state.error = "Erro ao buscar exame.";
    });
    builder.addCase(removeComment.fulfilled, (state, { payload }) => {
      console.log(payload.id);
      console.log(state.comments.length);
      state.comments.forEach((c) => console.log(`id: ${c.id}`));
      state.comments = state.comments.filter((c) => c.id !== payload.id);
      console.log(state.comments.length);
    });
    builder.addCase(removeComment.rejected, (state) => {
      state.error = "Erro ao remover comentário.";
    });
    builder.addCase(updateComment.fulfilled, (state, { payload }) => {
      const idx = state.comments.findIndex((c) => c.id === payload.id);
      state.comments[idx] = payload;
    });
    builder.addCase(updateComment.rejected, (state) => {
      state.error = "Erro ao atualizar comentário.";
    });
  },
});
export const {
  reset,
  clearError,
  setCriteriaValue,
  setCorrectionValidity,
  setFinalComment,
  setRotation,
  setFinished,
} = correctionSlice.actions;
export default correctionSlice.reducer;
