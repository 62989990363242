import React from 'react';
import {Dialog, DialogContent} from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    open: boolean;
    onClose: () => void;
    id?: string
}

const PreviousCorrection: React.FC<Props> = ({open, onClose, id}) => {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth={true}>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Reescrita de:
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{minHeight: '95vh', minWidth: "100%"}}>
                <iframe style={{border: 0, margin: 0, padding: 0, height: '95vw', minWidth: "100%"}}
                        src={`${process.env.REACT_APP_PREVIOUS_CORRECTION_URL}/${id}`}/>
            </DialogContent>
        </Dialog>
    );
};

export default PreviousCorrection;
