import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  clearError,
  finishCorrection,
  getComments,
  getCorrection,
  getCorrectionCriteria,
  getCorrectionValidity,
  getEssay,
  getExam,
  getTheme,
  reset,
  setFinished,
} from "./correction.slice";
import React, { useEffect, useState } from "react";
import TextCorrection from "./components/text-correction.component";
import { Box, Button, Container } from "@mui/material";
import { getNextCorrection } from "../queues/correction-queues.slice";
import ImageCorrection from "./components/image-correction.component";
import Theme from "../../../app/components/theme.component";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import HistoryIcon from "@mui/icons-material/History";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PreviousCorrection from "./components/previous-correction.component";

interface IBaseCorrection {
  id?: number;
  readonly?: boolean;
  onFinish?: () => void;
  origin?: string;
}

export default function BaseCorrection(props: IBaseCorrection) {
  const navigation = useNavigate();
  let { id } = useParams();
  const dispatch = useAppDispatch();
  const correctionState = useAppSelector((state) => state.correction);
  const moderationState = useAppSelector((state) => state.moderation);
  const authState = useAppSelector((state) => state.auth);
  const [showTheme, setShowTheme] = useState(false);
  const [showPreviousCorrection, setShowPreviousCorrection] = useState(false);
  const [next, setNext] = useState(false);

  const onFinish = async (getNext: boolean) => {
    setNext(getNext);
    dispatch(finishCorrection(correctionState.correction));
  };

  const goToNext = () => {
    if (correctionState.essay?.examId && next) {
      let examId = correctionState.essay.examId;
      dispatch(getNextCorrection(examId));
    }

    dispatch(reset());
    if (props.onFinish) {
      props.onFinish();
    } else {
      navigation(`/teacher/queues`);
    }
  };

  useEffect(() => {
    if (correctionState.essay) {
      if (
        correctionState.essay.status !== "corrected" &&
        correctionState.correction.teacherId === authState.authData?.user.id
      ) {
        dispatch(getCorrectionValidity(correctionState.essay.examId));
        dispatch(getCorrectionCriteria(correctionState.essay.examId));
        dispatch(getTheme(correctionState.essay.themeId));
        dispatch(getExam(correctionState.essay.examId));
      } else if (correctionState.essay.status === "corrected") {
        dispatch(getCorrectionValidity(correctionState.essay.examId));
        dispatch(getExam(correctionState.essay.examId));
        dispatch(getCorrectionCriteria(correctionState.essay.examId));
      }
    }
  }, [correctionState.essay?.themeId]);

  useEffect(() => {
    if (correctionState.correction.essayId) {
      dispatch(getEssay(correctionState.correction.essayId));
    }
  }, [correctionState.correction.essayId]);

  useEffect(() => {
    if (props.origin) {
      if (
        moderationState.isEditing &&
        moderationState.originalValidity !==
          correctionState.correction.correctionValidityId
      ) {
        const validity = correctionState.validity.find(
          (v: any) => v.id === correctionState.correction.correctionValidityId
        );

        if (validity?.onChecklist && !validity?.isValid) {
          onFinish(true);
        }
      }
    } else {
      if (!props.readonly) {
        const validity = correctionState.validity.find(
          (v: any) => v.id === correctionState.correction.correctionValidityId
        );
        if (validity?.onChecklist && !validity?.isValid) {
          onFinish(true);
        }
      }
    }
  }, [correctionState.correction.correctionValidityId]);

  useEffect(() => {
    if (props.id) {
      id = props.id?.toString();
    }
    if (id) {
      dispatch(getCorrection(parseInt(id)));
      dispatch(getComments(parseInt(id)));
    }
  }, [props.id]);

  useEffect(() => {
    if (correctionState.error) {
      const swal = withReactContent(Swal);
      swal
        .fire({
          title: "Ops...",
          text: correctionState.error,
          icon: "error",
          confirmButtonText: "Ok",
        })
        .then(() => dispatch(clearError()));
    }
  }, [correctionState.error]);

  useEffect(() => {
    if (!correctionState.onError && correctionState.success) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      const swal = withReactContent(Swal);
      swal
        .fire({
          title: "Sucesso",
          text: "Correção realizada com sucesso",
          icon: "success",
          confirmButtonText: "Ok",
        })
        .then(() => goToNext());
    }
  }, [correctionState.loading]);

  if (correctionState.essay?.id) {
    return (
      <Box>
        {props.readonly || (
          <AppBar position="static" color="default">
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1, display: "flex" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      my: 2,
                      color: "#333",
                      display: "block",
                      marginLeft: "50px",
                    }}
                  >
                    <b>Exame</b>: {correctionState.exam.name}
                  </Typography>
                  <Button
                    sx={{ my: 2, marginLeft: "50px" }}
                    onClick={() => setShowTheme(true)}
                    variant="outlined"
                    color="primary"
                  >
                    <TextSnippetIcon /> Ver Proposta
                  </Button>
                  {correctionState.essay.previousEssayId && (
                    <Button
                      sx={{ my: 2, marginLeft: "50px" }}
                      onClick={() => setShowPreviousCorrection(true)}
                      variant="outlined"
                      color="primary"
                    >
                      <HistoryIcon /> Ver correção anterior
                    </Button>
                  )}
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
        )}
        <Box p={2}>
          {correctionState.essay?.url ? (
            <ImageCorrection
              onFinish={onFinish}
              readonly={
                !moderationState.isEditing
                  ? props.readonly
                  : !moderationState.isEditing
              }
            />
          ) : (
            <TextCorrection
              onFinish={onFinish}
              readonly={
                !moderationState.isEditing
                  ? props.readonly
                  : !moderationState.isEditing
              }
            />
          )}
        </Box>
        <Theme
          open={showTheme}
          onClose={() => setShowTheme(false)}
          theme={correctionState.theme}
        />
        <PreviousCorrection
          open={showPreviousCorrection}
          onClose={() => setShowPreviousCorrection(false)}
          id={correctionState.essay.previousEssayId}
        />
      </Box>
    );
  } else {
    return <Box p={2}>Carregando redação...</Box>;
  }
}
