import {Box, Button, Menu, MenuItem, Popover, Switch, TextareaAutosize, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ICorrectionCriteria} from "../../../../app/interfaces";
import {addComment} from "../correction.slice";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";

interface Props {
    currentSelection: { start?: number, end?: number, top?: number, left?: number, height?: number, width?: number } | null
    contextMenu: { mouseX: number, mouseY: number } | null
    onClose: () => void
}

const CommentPopover: React.FC<Props> = (props: Props) => {
    const [currentCriteria, setCurrentCriteria] = useState<ICorrectionCriteria | null>(null)
    const correctionState = useAppSelector((state) => state.correction);
    const dispatch = useAppDispatch();
    const [currentComment, setCurrentComment] = useState<string>('')
    const [isPositiveComment, setPositiveComment] = useState<boolean>(false)
    const openComment = (criteria: ICorrectionCriteria) => {
        setCurrentCriteria(criteria);
    }

    const saveComment = () => {
        if (props.currentSelection && currentCriteria) {

            let correctionId = correctionState.correction.id || 0;
            dispatch(addComment({
                isPositive: isPositiveComment,
                text: currentComment,
                criteriaId: currentCriteria.id,
                correctionId,
                ...props.currentSelection
            }))
            setPositiveComment(false)
        }
        handleClose();
    }

    const handleClose = () => {
        setCurrentCriteria(null);
        setCurrentComment('');
        props.onClose();
    };

    useEffect(() => {

    }, [props.contextMenu])

    return (<Box>
        <Menu
            open={props.contextMenu !== null && currentCriteria === null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                props.contextMenu !== null
                    ? {top: props.contextMenu.mouseY + 2, left: props.contextMenu.mouseX - 6}
                    : undefined
            }
        >
            {
                correctionState.criteria.map(
                    (c, index) => <MenuItem key={index} onClick={() => openComment(c)}>
                        {c.name}
                    </MenuItem>)
            }
        </Menu><Popover
        open={props.contextMenu !== null && currentCriteria !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
            props.contextMenu !== null
                ? {top: props.contextMenu.mouseY + 2, left: props.contextMenu.mouseX - 6}
                : undefined
        }
    >
        <Box p={3}>
            <Typography sx={{p: 2}}>{currentCriteria?.name}</Typography>

            <TextareaAutosize autoFocus={true}
                              defaultValue={currentComment}
                              onChange={(event) => setCurrentComment(event.currentTarget.value)}
                              minRows={5}
                              placeholder="Digite seu comentário..."
            />
            <Box textAlign='right'>
                <Box textAlign='left'><Switch defaultChecked={false}
                                              onChange={(event, value) => setPositiveComment(value)}/> Positivo</Box>
                <Button disabled={!currentComment?.length} variant='outlined'
                        onClick={() => saveComment()}>Salvar</Button>
            </Box>
        </Box>
    </Popover></Box>)
}

export default CommentPopover;
