import {Image as KonvaImage, Layer, Stage} from 'react-konva';
import React, {ReactNode, RefObject, useEffect, useRef} from "react";
import Konva from "konva";
import KonvaEventObject = Konva.KonvaEventObject;

export interface IEssayImageProps {
    children?: ReactNode
    drawing?: ReactNode
    image: HTMLImageElement
    rotation: number
    onMouseDown?: (event: KonvaEventObject<MouseEvent>, stageRef: RefObject<Konva.Stage>) => void
    onMouseMove?: (event: KonvaEventObject<MouseEvent>, stageRef: RefObject<Konva.Stage>) => void
    onMouseUp?: (event: KonvaEventObject<MouseEvent>, stageRef: RefObject<Konva.Stage>) => void
}

export default function EssayImage(props: IEssayImageProps) {
    const stageRef = useRef<Konva.Stage>(null);
    const layerRef = useRef<Konva.Layer>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<Konva.Image>(null);

    const handleResize = () => {
        const image = props.image;
        const div = divRef.current;
        const stage = stageRef.current;

        if (div && stage && image?.width && image.height) {
            let aspectRatio = image.width / image.height;
            if ((props.rotation / 90) % 2 !== 0) {
                aspectRatio = image.height / image.width;
            }

            let minWidth = div.clientWidth > 850 ? div.clientWidth : 650;
            let height = minWidth / aspectRatio;
            let width = height * aspectRatio;

            stage.width(width);
            stage.height(height);
            if (imageRef.current) {
                imageRef.current.x(width / 2)
                imageRef.current.y(height / 2)
                if ((props.rotation / 90) % 2 !== 0) {
                    const tmp = height;
                    height = width
                    width = tmp
                }
                imageRef.current.offsetX(width / 2)
                imageRef.current.offsetY(height / 2)
                imageRef.current.height(height);
                imageRef.current.width(width);
            }
            stage.batchDraw();
        }
    };

    useEffect(() => {
        handleResize();
    }, [props.rotation]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        props.image.onload = () => {
            handleResize();
        };
    }, []);

    return (
        <div ref={divRef} style={{position: 'relative', flex: 1, flexFlow: 'row wrap', flexGrow: 1}}>
            <Stage
                ref={stageRef}
                onMouseDown={(evt) => {
                    if (props.onMouseDown) props.onMouseDown(evt, stageRef)
                }}
                onMouseMove={(evt) => {
                    if (props.onMouseMove) props.onMouseMove(evt, stageRef)
                }}
                onMouseUp={(evt) => {
                    if (props.onMouseUp) props.onMouseUp(evt, stageRef)
                }}
            >
                <Layer ref={layerRef}
                       style={{
                           marginLeft: 'auto',
                           marginRight: 'auto'
                       }}>
                    <KonvaImage ref={imageRef} image={props.image}
                                rotation={props.rotation}/>
                    {props.drawing}
                </Layer>
            </Stage>
            {props.children}
        </div>
    )
}
