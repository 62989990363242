import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { setCorrectionValidity, setCriteriaValue } from "../correction.slice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import ScoreCards from "./score-card.component";

export default function CorrectionScore(props: { readonly?: boolean }) {
  const correctionState = useAppSelector((state) => state.correction);
  const dispatch = useAppDispatch();
  const [isValid, setValid] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [criteriaValues, setCriteriaValues] = useState<any>({});
  const setCriteriaScore = (criteriaId: number, id: number, value: number) => {
    dispatch(setCriteriaValue({ criteriaId, id, value }));
  };

  const getCriteriaValue = (criteriaId: number) => {
    const value = correctionState.correction.criteriaValues.find(
      (cv) => cv.criteriaId === criteriaId
    );
    if (value) {
      return {
        label: value.value.toString(),
        id: value.id,
      };
    }
    return null;
  };

  useEffect(() => {
    const isValid = correctionState.validity.find(
      (v) => v.id === correctionState.correction.correctionValidityId
    )?.isValid;
    setValid(isValid || false);
  }, [
    correctionState.correction.correctionValidityId,
    correctionState.validity,
  ]);

  const setCorrectionValues = () => {
    let values = criteriaValues;
    correctionState.criteria.forEach((c) => {
      values = { ...values, [c.id]: getCriteriaValue(c.id) };
    });
    setCriteriaValues(values);
  };

  useEffect(setCorrectionValues, [
    correctionState.correction.criteriaValues,
    correctionState.criteria,
    criteriaValues,
  ]);

  const calculateTotalScore = () => {
    let total = correctionState.criteria
      .map((c) => +(getCriteriaValue(c.id)?.label || 0) * +c.weight)
      .reduce((total: number, current: number) => {
        total += current;
        return total;
      }, 0);
    if (correctionState.exam.maxScore) {
      const maxValues = [];
      for (const criteria of correctionState.criteria) {
        const max = criteria.values.reduce((prev, current) => {
          return Number(prev.value) > Number(current.value) ? prev : current;
        });
        maxValues.push(max.value);
      }

      const sum = maxValues.reduce(
        (prev, current) => Number(prev) + Number(current)
      );

      total = (correctionState.exam.maxScore * total) / sum;
    }
    setTotalScore(total);
  };

  useEffect(() => {
    if (isValid) {
      correctionState.criteria.map((criteria) => {
        const zero =
          criteria.values.find((value) => Number(value.value) === 0) ||
          criteria.values[0];
        dispatch(
          setCriteriaValue({
            criteriaId: zero.criteriaId,
            id: zero.id,
            value: zero.value,
          })
        );
      });
    }
  }, [isValid]);

  useEffect(calculateTotalScore, [correctionState.correction.criteriaValues]);

  return (
    <Box>
      <Autocomplete
        readOnly={props.readonly}
        disablePortal
        fullWidth={true}
        onChange={(event, value) => {
          dispatch(setCorrectionValidity(value?.id));
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={
          correctionState.validity
            .map((v) => {
              return { label: v.name, ...v };
            })
            .find(
              (v) => v.id === correctionState.correction.correctionValidityId
            ) || null
        }
        options={correctionState.validity
          .filter((v) => v.onCorrection)
          .map((status) => {
            return { id: status.id, label: status.name };
          })}
        renderInput={(params) => <TextField {...params} label="Status" />}
        sx={{ marginBottom: "2rem" }}
      />
      {isValid &&
        correctionState.criteria.map((criteria, index) => (
          <Box sx={{ marginTop: "2rem" }} key={index}>
            <Typography sx={{ fontSize: "1rem" }}>{criteria.name}</Typography>
            <ScoreCards
              data={criteria.values}
              setScore={(crit, value, id) => setCriteriaScore(crit, id, value)}
            />
          </Box>
        ))}
      <Box p={3}>
        <Typography
          align="center"
          color="secondary"
          variant={"h5"}
        >{`Nota total: ${
          totalScore === 0 && isValid
            ? "Atribua uma nota"
            : totalScore.toFixed(2)
        }`}</Typography>
      </Box>
    </Box>
  );
}
