import { AxiosResponse } from "axios";
import { api } from "../../app/axios";
import { ICorrection, IEssay, IExam } from "../../app/interfaces";

const getPendingModeration = (): Promise<IEssay[]> => {
  return api
    .get("/api/v1/essays/pending_moderation")
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getFeedbacks = (): Promise<IEssay[]> => {
  return api.get("/api/v1/essays/feedbacks").then((response: AxiosResponse) => {
    return response.data;
  });
};

const getExams = (): Promise<IExam[]> => {
  return api.get("/api/v1/exams").then((response: AxiosResponse) => {
    return response.data;
  });
};

const getEssayCorrections = (essayId: number): Promise<ICorrection[]> => {
  return api
    .get(`/api/v1/essays/${essayId}/corrections`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const approve = (correctionId: number): Promise<void> => {
  return api
    .patch(`/api/v1/corrections/${correctionId}/approve`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const newCorrection = (essayId: number): Promise<IEssay> => {
  return api
    .patch(`/api/v1/essays/${essayId}/new_correction`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const duplicateCorrection = (correctionId: number): Promise<ICorrection> => {
  return api
    .post(`/api/v1/corrections/${correctionId}/duplicate`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const moderatorService = {
  getPendingModeration,
  getExams,
  getEssayCorrections,
  approve,
  newCorrection,
  getFeedbacks,
  duplicateCorrection,
};

export default moderatorService;
