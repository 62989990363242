import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  List,
  ListItemButton,
  ListItemText,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { IEssay } from "../../../app/interfaces";
import { getExams, getFeedbacks, setSelectedEssay } from "../moderator.slice";
import { reset } from "../../teacher/correction/correction.slice";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function FeedbackEssayList() {
  const dispatch = useAppDispatch();
  const moderationState = useAppSelector((state) => state.moderation);
  const [filter, setFilter] = useState("");
  const [filteredEssays, setFilteredEssays] = useState<IEssay[]>([]);

  useEffect(() => {
    setFilter("");
    dispatch(getExams());
    dispatch(getFeedbacks());
  }, []);

  useEffect(() => setFilter("all"), [moderationState.exams]);

  useEffect(() => {
    setFilteredEssays(
      moderationState.essays.filter(
        (e) => filter === "all" || e.examId?.toString() == filter
      )
    );
    dispatch(setSelectedEssay(moderationState.essays[0]));
  }, [filter, moderationState.essays]);

  const handleItemClick = (item: IEssay) => {
    dispatch(reset());
    dispatch(setSelectedEssay(item));
  };

  const getExamName = (examId: number | undefined): string | undefined => {
    return moderationState.exams.find((e) => e.id === examId)?.name;
  };
  return (
    <Grid2 container>
      <Grid2 xs={12} margin={3}>
        <div style={{ padding: "0.5em" }}>
          <Select
            fullWidth={true}
            label="Exame"
            value={filter}
            onChange={(evt: SelectChangeEvent) => setFilter(evt.target.value)}
          >
            {[{ id: "all", name: "Todos" }, ...moderationState.exams].map(
              (exam) => (
                <MenuItem key={exam.id} value={exam.id}>
                  {exam.name}
                </MenuItem>
              )
            )}
          </Select>
        </div>
        <List
          sx={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden" }}
        >
          {filteredEssays.map((item: IEssay) => (
            <ListItemButton
              key={item.id}
              onClick={() => handleItemClick(item)}
              selected={moderationState.selectedEssay?.id === item.id}
            >
              <ListItemText
                primary={`Feedback: ${item.feedbackRating}`}
                secondary={`${
                  item.feedbackReason === null
                    ? "Nenhum motivo informado"
                    : item.feedbackReason
                }`}
              />
            </ListItemButton>
          ))}
          {filteredEssays.length === 0 && (
            <Typography variant="h6">Nenhuma redação encontrada.</Typography>
          )}
        </List>
      </Grid2>
    </Grid2>
  );
}
