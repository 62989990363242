import {AxiosResponse} from "axios";
import {api} from "../../../app/axios";
import {ITransaction} from "../../../app/interfaces";


const balance = (): Promise<number> => {
    return api
        .get("/api/v1/transactions/balance")
        .then((response: AxiosResponse) => {
            return response.data;
        });
};

const getTransactions = (): Promise<ITransaction[]> => {
    return api
        .get("/api/v1/transactions")
        .then((response: AxiosResponse) => {
            return response.data;
        });
};

const walletService = {
    balance,
    getTransactions
};

export default walletService;
