import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import authReducer from '../features/auth/user.slice';
import correctionQueuesReducer from '../features/teacher/queues/correction-queues.slice';
import correctionReducer from '../features/teacher/correction/correction.slice';
import walletReducer from '../features/teacher/wallet/wallet.slice';
import moderationReducer from '../features/moderator/moderator.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        queues: correctionQueuesReducer,
        correction: correctionReducer,
        wallet: walletReducer,
        moderation: moderationReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
