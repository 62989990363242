import Grid2 from "@mui/material/Unstable_Grid2";
import styles from "../../features/teacher/correction/correction.module.css";
import {MouseEventHandler, ReactNode} from "react";

export interface IPaperProps {
    highlightedText: ReactNode[]
    text?: string
    children?: ReactNode
    onMouseUp?: MouseEventHandler
}

export default function EssayPaper(props: IPaperProps) {
    return (
        <Grid2 xs={12} className={styles.paper}>
            <div className={styles.lineCount}>
                {Array.from(Array(30).keys()).map((k, index) => {
                    return <div key={index} className={styles.line}>{index + 1}</div>
                })}
            </div>
            <div className={styles.paperBg}>
                {props.highlightedText}
            </div>
            <div id="textContainer" className={styles.paperBg} onMouseUp={(event) => {
                if (props.onMouseUp) props.onMouseUp(event)
            }}
                 style={{zIndex: 10, color: 'transparent', position: 'absolute', top: 0}}>
                {props.text ? props.text : ''}
            </div>
            {props.children}
        </Grid2>)
}
