import {Slider, Typography} from "@mui/material";
import {useEffect, useState} from "react";

type scores = {
    id: number;
    value: number;
    criteriaId: number;
};

export interface IScoreCards {
    data: scores[] | undefined;
    setScore: (crit: any, value: any, id: any) => void;
    defaultValue?: number;
    isEditing?: boolean;
}

const ScoreCards = (props: IScoreCards) => {
    const {data, setScore, defaultValue, isEditing} = props;
    const [criteria, setCriteria] = useState<number | undefined>(
        defaultValue || 0
    );
    const [max, setMax] = useState<number>(5);

    useEffect(() => {
        const array: number[] = data?.map((score) => score.value) || [];
        array.sort((a, b) => a - b);
        const max = Math.max(...array);
        setMax(max);
    }, [data]);

    return (
        <div
            style={{
                width: "100%",
                padding: "0.6rem 0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
            }}
        >
            <Slider
                aria-label="Nota"
                defaultValue={defaultValue}
                onChange={(e: any) => {
                    const score = data?.find((score) => score.value == e.target.value);
                    setScore(score?.criteriaId, score?.value, score?.id);
                    setCriteria(score?.value);
                }}
                valueLabelDisplay="auto"
                disabled={isEditing || false}
                step={null}
                marks={data?.map((score) => {
                    const num = Number(score.value);
                    return {
                        value: score.value,
                        label: Number.isInteger(num) ? num.toFixed() : num.toFixed(1),
                    };
                })}
                min={0}
                max={max}
                sx={{width: "85%"}}
            />
            <Typography
                sx={{fontSize: "1rem", fontWeight: 700, marginLeft: "0.4rem"}}
            >
                {criteria}
            </Typography>
        </div>
    );
};

export default ScoreCards;
