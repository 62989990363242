import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moderatorService from "./moderator.service";
import {
  ICorrection,
  ICriteriaValue,
  IEssay,
  IExam,
} from "../../app/interfaces";

export interface ModerationState {
  essays: IEssay[];
  exams: IExam[];
  selectedEssay?: IEssay;
  selectedEssayCorrections: ICorrection[];

  error?: string;
  duplicateCorrection?: ICorrection;

  isEditing: boolean;
  originalValidity?: number;
  selectedValues?: ICriteriaValue[];
}

export const getEssaysPendingModeration = createAsyncThunk(
  "moderation/essays/pendingModeration",
  async () => {
    return await moderatorService.getPendingModeration();
  }
);

export const getFeedbacks = createAsyncThunk(
  "moderation/essays/feedbacks",
  async () => {
    return await moderatorService.getFeedbacks();
  }
);

export const getExams = createAsyncThunk("moderation/exam/get", async () => {
  return moderatorService.getExams();
});

export const getEssayCorrections = createAsyncThunk(
  "moderation/correction/get",
  async (id: number) => {
    return moderatorService.getEssayCorrections(id);
  }
);

export const approveCorrection = createAsyncThunk(
  "moderation/correction/approve",
  async (correctionId: number) => {
    return moderatorService.approve(correctionId);
  }
);

export const newCorrection = createAsyncThunk(
  "moderation/correction/disapprove",
  async (essayId: number) => {
    return moderatorService.newCorrection(essayId);
  }
);

export const duplicateCorrection = createAsyncThunk(
  "moderation/correction/duplicate",
  async (correctionId: number) => {
    return moderatorService.duplicateCorrection(correctionId);
  }
);

const initialState: ModerationState = {
  essays: [],
  exams: [],
  selectedEssayCorrections: [],
  isEditing: false,
};

const moderatorSlice = createSlice({
  name: "moderation",
  initialState,
  reducers: {
    setSelectedEssay: (state, action: PayloadAction<IEssay>) => {
      state.selectedEssay = action.payload;
      state.duplicateCorrection = undefined;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    finishDuplicate: (state) => {
      state.duplicateCorrection = undefined;
    },
    initEdit: (state, action: PayloadAction<number>) => {
      state.isEditing = true;
      state.originalValidity = action.payload;
    },
    finishEdit: (state) => {
      state.isEditing = false;
      state.originalValidity = undefined;
    },
    setCriteriaValues: (state, action: PayloadAction<ICriteriaValue[]>) => {
      state.selectedValues = action.payload;
    },
    cleanCriteriaValues: (state) => {
      state.selectedValues = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEssaysPendingModeration.fulfilled,
      (state, { payload }) => {
        state.essays = payload;
      }
    );
    builder.addCase(getEssaysPendingModeration.rejected, (state) => {
      state.error = "Erro ao buscar redações pendentes de moderação";
    });
    builder.addCase(getFeedbacks.fulfilled, (state, { payload }) => {
      state.essays = payload;
    });
    builder.addCase(getFeedbacks.rejected, (state) => {
      state.error = "Erro ao buscar redações com feedback negativo";
    });
    builder.addCase(getExams.fulfilled, (state, { payload }) => {
      state.exams = payload;
    });
    builder.addCase(getExams.rejected, (state) => {
      state.error = "Erro ao buscar lista de exames";
    });
    builder.addCase(getEssayCorrections.fulfilled, (state, { payload }) => {
      state.selectedEssayCorrections = payload;
    });
    builder.addCase(getEssayCorrections.rejected, (state) => {
      state.error = "Erro ao buscar correções da redação";
    });
    builder.addCase(duplicateCorrection.fulfilled, (state, { payload }) => {
      state.selectedEssayCorrections.push(payload);
      state.duplicateCorrection = payload;
    });
    builder.addCase(duplicateCorrection.rejected, (state) => {
      state.error = "Erro ao duplicar a correção";
    });
    builder.addCase(approveCorrection.fulfilled, (state, { payload }) => {
      state.essays = [];
      state.selectedEssayCorrections = [];
      state.selectedEssay = undefined;
    });
    builder.addCase(approveCorrection.rejected, (state) => {
      state.error = "Erro ao aprovar correção";
    });
    builder.addCase(newCorrection.fulfilled, (state, { payload }) => {
      state.essays = [];
      state.selectedEssayCorrections = [];
      state.selectedEssay = undefined;
    });
    builder.addCase(newCorrection.rejected, (state) => {
      state.error = "Erro ao solicitar nova correção";
    });
  },
});

export const {
  setSelectedEssay,
  finishDuplicate,
  initEdit,
  finishEdit,
  setCriteriaValues,
  cleanCriteriaValues,
} = moderatorSlice.actions;
export default moderatorSlice.reducer;
