import {AxiosResponse} from "axios";
import {IAuthData} from "./user.slice";
import {api} from "../../app/axios";


const login = (username: string, password: string): Promise<IAuthData> => {
    return api
        .post("/auth/login", {
            username,
            password,
        })
        .then((response: AxiosResponse) => {
            if (response.data.access_token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
};

const changePassword = (currentPassword: string, newPassword: string) => {
    return () => {
        return api.patch('/api/v1/users/password', {currentPassword, newPassword})
    };
};

const userService = {
    login,
    changePassword
};

export default userService;

