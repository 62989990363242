import { api, apiAprova } from "../../../app/axios";
import { AxiosResponse } from "axios";
import {
  IComment,
  ICorrection,
  ICorrectionCriteria,
  ICorrectionValidity,
  IEssay,
  IExam,
  IQueue,
  ITheme,
} from "../../../app/interfaces";

const getQueues = (): Promise<IQueue[]> => {
  return api.get("/api/v1/essays/queues").then((response: AxiosResponse) => {
    return response.data;
  });
};

const getNextCorrection = (examId: number): Promise<number> => {
  return api
    .post("/api/v1/corrections/", { examId })
    .then((response: AxiosResponse) => {
      return response.data.id;
    });
};

const getCorrection = (correctionId: number): Promise<ICorrection> => {
  return api
    .get(`/api/v1/corrections/${correctionId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const saveCorrection = (correction: ICorrection): Promise<ICorrection> => {
  return api
    .patch(`/api/v1/corrections/${correction.id}`, correction)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getEssay = (essayId: number): Promise<IEssay> => {
  return api
    .get(`/api/v1/essays/${essayId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getCorrectionValidity = (
  examId: number
): Promise<ICorrectionValidity[]> => {
  return api
    .get(`/api/v1/exams/${examId}/correction_validity`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getCorrectionCriteria = (
  examId: number
): Promise<ICorrectionCriteria[]> => {
  return api
    .get(`/api/v1/exams/${examId}/criteria`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const addComment = (comment: IComment): Promise<IComment> => {
  return api
    .post(`/api/v1/corrections/${comment.correctionId}/comments`, comment)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getComments = (correctionId: number): Promise<IComment[]> => {
  return api
    .get(`/api/v1/corrections/${correctionId}/comments`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const removeComment = (comment: IComment): Promise<IComment> => {
  return api
    .delete(
      `/api/v1/corrections/${comment.correctionId}/comments/${comment.id}`
    )
    .then((response: AxiosResponse) => {
      return comment;
    });
};

const updateComment = (comment: IComment): Promise<IComment> => {
  return api
    .patch(
      `/api/v1/corrections/${comment.correctionId}/comments/${comment.id}`,
      comment
    )
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getTheme = (themeId: string): Promise<ITheme> => {
  return apiAprova
    .get(`/api/backend/v2/essaythemes/details/${themeId}`)
    .then((response: AxiosResponse) => {
      return response.data;
    });
};

const getExam = (examId: number): Promise<IExam> => {
  return api.get(`/api/v1/exams/${examId}`).then((response: AxiosResponse) => {
    return response.data;
  });
};

const correctionService = {
  getQueues,
  getNextCorrection,
  getCorrection,
  saveCorrection,
  getEssay,
  getCorrectionValidity,
  getCorrectionCriteria,
  addComment,
  getComments,
  removeComment,
  updateComment,
  getTheme,
  getExam,
};

export default correctionService;
