import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { ICorrection } from "../../../app/interfaces";
import { useAppDispatch } from "../../../app/hooks";
import {
  approveCorrection,
  duplicateCorrection,
  finishEdit,
  getEssaysPendingModeration,
  getFeedbacks,
  initEdit,
  setCriteriaValues,
} from "../../../features/moderator/moderator.slice";
import { reset } from "../../../features/teacher/correction/correction.slice";

interface ICorrectionTable {
  data: ICorrection[];
  setSelected: (correction: ICorrection | null) => void;
  selectedId: number;
  origin: string;
  approvedId?: number;
}

const CorrectionTable = (props: ICorrectionTable) => {
  const { data, setSelected, selectedId } = props;
  const dispatch = useAppDispatch();

  const approve = (event: React.MouseEvent, correctionId: number) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(approveCorrection(correctionId)).then(() => {
      dispatch(reset());
      setSelected(null);
      dispatch(
        props.origin === "Moderation"
          ? getEssaysPendingModeration()
          : getFeedbacks()
      );
    });
  };

  const recorrect = (event: React.MouseEvent, correctionId: number) => {};

  const edit = (
    event: React.MouseEvent,
    correctionId: number,
    validityId: number
  ) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(duplicateCorrection(correctionId));
    dispatch(initEdit(validityId));
  };

  const returnApprove = (correctionId: number | undefined) => {
    if (props.origin !== "Moderation") {
      if (correctionId === props.approvedId) {
        return (
          <Button
            variant="text"
            onClick={(evt) => approve(evt, correctionId || 0)}
          >
            Manter
          </Button>
        );
      } else {
        return (
          <Button
            variant="text"
            onClick={(evt) => approve(evt, correctionId || 0)}
          >
            Selecionar
          </Button>
        );
      }
    }

    return (
      <Button variant="text" onClick={(evt) => approve(evt, correctionId || 0)}>
        Aprovar
      </Button>
    );
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "70vh", overflowY: "scroll", overflowX: "hidden" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Corretor</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Critérios</TableCell>
              <TableCell align="center">Nota Total</TableCell>
              <TableCell align="center">Comentários</TableCell>
              <TableCell align="center">Feedback</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((correction: ICorrection) => (
              <TableRow
                key={correction.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                selected={correction.id === selectedId}
                onClick={() => {
                  if (correction.id !== selectedId) {
                    dispatch(reset());
                    dispatch(finishEdit());
                    setSelected(correction);
                    dispatch(setCriteriaValues(correction.criteriaValues));
                  }
                }}
              >
                <TableCell component="th" scope="row">
                  {correction.teacher?.name}
                </TableCell>
                <TableCell>{correction.teacher?.email}</TableCell>
                <TableCell>{correction.correctionValidity?.name}</TableCell>
                <TableCell>
                  {correction.criteriaValues.map((c) => c.value).join(" / ")}
                </TableCell>
                <TableCell align="center">{correction.finalScore}</TableCell>
                <TableCell align="center">
                  {correction.comments?.length}
                </TableCell>
                <TableCell align="center">
                  {correction.feedbackRating}
                </TableCell>
                <TableCell>
                  {returnApprove(correction.id)}
                  <Button
                    variant="text"
                    onClick={(evt) => recorrect(evt, correction.id || 0)}
                  >
                    Recorrigir
                  </Button>
                  <Button
                    variant="text"
                    onClick={(evt) =>
                      edit(
                        evt,
                        correction.id || 0,
                        correction.correctionValidityId || 0
                      )
                    }
                  >
                    Editar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CorrectionTable;
