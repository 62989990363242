import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { login } from "./user.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import logo from "../../assets/images/logo.svg";

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [attempted, setAttempted] = useState(false);
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData = {
      email: (data.get("email") || "").toString(),
      password: (data.get("password") || "").toString(),
    };
    dispatch(
      login({ username: loginData.email, password: loginData.password })
    ).then(() => {
      setAttempted(true);
    });
  };

  useEffect(() => {
    if (attempted && !authState.isLoggedIn) {
      const swal = withReactContent(Swal);
      swal.fire({
        title: "Ops...",
        text: "Login ou senha inválidos",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [attempted]);

  if (authState.isLoggedIn)
    switch (authState.authData?.user.role) {
      case "teacher":
        navigate("/teacher/dashboard");
        break;
      case "moderator":
        navigate("/moderator/moderation");
        break;
    }

  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "140px",
          backgroundColor: theme.palette.primary.main,
          display: "flex",
        }}
      >
        <img
          style={{ height: "100px", flex: 1, marginTop: "15px" }}
          src={logo}
        />
      </div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" color="secondary">
            Plataforma de Redações
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Entrar
            </Button>
            {/*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
        </Grid>*/}
          </Box>
        </Box>
      </Container>
    </div>
  );
}
