import { useAppSelector } from "../../../../app/hooks";
import CorrectionChecklist from "./correction-checklist.component";
import { MouseEvent, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import Correction from "./correction.component";
import CommentPopover from "./comment-popover.component";
import EssayPaper from "../../../../app/components/essay-paper.component";
import { Tooltip } from "@mui/material";

export default function TextCorrection(props: {
  readonly?: boolean;
  onFinish: (next: boolean) => void;
}) {
  const correctionState = useAppSelector((state) => state.correction);
  const [inCorrection, setInCorrection] = useState(false);
  const [highlightedText, setHighlightedText] = useState<any[]>([]);
  const [currentSelection, setCurrentSelection] = useState<{
    start: number;
    end: number;
  } | null>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const startCorrection = () => {
    setInCorrection(true);
  };

  useEffect(() => {
    updateHighlightedText();
  }, [correctionState.essay?.text]);

  useEffect(() => {
    updateHighlightedText();
  }, [correctionState.comments, correctionState.criteria]);

  const handleSelection = (event: MouseEvent) => {
    if (!inCorrection) return;
    const selection = window.getSelection();
    if (selection && selection.anchorOffset !== selection.focusOffset) {
      const start =
        selection.anchorOffset > selection.focusOffset
          ? selection.focusOffset
          : selection.anchorOffset;
      const end =
        selection.anchorOffset < selection.focusOffset
          ? selection.focusOffset
          : selection.anchorOffset;
      setCurrentSelection({ start, end });
      handleContextMenu(event);
    }
  };

  const handleContextMenu = (event: MouseEvent) => {
    if (!inCorrection) return;
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX,
            mouseY: event.clientY,
          }
        : null
    );
  };

  const updateHighlightedText = () => {
    const rawText = correctionState.essay?.text + "";
    let newStatementArray: any[] = [];
    let last: number | undefined = 0;
    let comments = [...correctionState.comments];
    if (comments.length) {
      setInCorrection(true);
      comments
        .sort((a, b) => (a.start || 0) - (b.start || 0))
        .forEach((comment, index) => {
          if (comment.start && comment.start >= (last || 0)) {
            newStatementArray.push(rawText.slice(last, comment.start));
            const criteria = correctionState.criteria.find(
              (c) => c.id === comment.criteriaId
            );
            newStatementArray.push(
              <Tooltip title={comment.text}>
                <span
                  key={index}
                  style={{
                    backgroundColor: criteria?.color,
                    zIndex: 100,
                    position: "relative",
                  }}
                >
                  {rawText.slice(comment.start, comment.end)}
                </span>
              </Tooltip>
            );
          }
          last = comment.end;
        });
    }
    newStatementArray.push(rawText.slice(last));
    setHighlightedText(newStatementArray);
  };

  return (
    <Grid2 container>
      <Grid2 xs={8}>
        <EssayPaper
          highlightedText={highlightedText}
          onMouseUp={handleSelection}
          text={correctionState.essay?.text}
        >
          <CommentPopover
            currentSelection={currentSelection}
            contextMenu={contextMenu}
            onClose={() => setContextMenu(null)}
          />
        </EssayPaper>
      </Grid2>
      <Grid2
        xs={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {inCorrection || props.readonly ? (
          <Correction onFinish={props.onFinish} readonly={props.readonly} />
        ) : (
          <CorrectionChecklist startCorrection={startCorrection} />
        )}
      </Grid2>
    </Grid2>
  );
}
