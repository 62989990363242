import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Button, CardActions, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import { setCorrectionValidity } from "../correction.slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export interface ICorrectionChecklist {
  startCorrection: () => void;
}

export default function CorrectionChecklist(props: ICorrectionChecklist) {
  const correctionState = useAppSelector((state) => state.correction);
  const moderationState = useAppSelector((state) => state.moderation);
  const dispatch = useAppDispatch();

  const reprove = async (id: number) => {
    if (moderationState.originalValidity) {
      if (moderationState.originalValidity === id) {
        const swal = withReactContent(Swal);
        swal.fire({
          title: "Aviso!",
          text: "Esse é o status da correção original",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    }

    await dispatch(setCorrectionValidity(id));
  };

  return (
    <Card sx={{ width: "90%" }}>
      <CardHeader title="Validar redação" subtitle="Selecione uma opção" />
      <CardActions sx={{ display: "flex", flexDirection: "column" }}>
        <Button size="small" onClick={() => props.startCorrection()}>
          Válida
        </Button>
        ou
        {correctionState.validity
          .filter((v) => v.onChecklist)
          .map((v, index) => {
            return (
              <Button key={index} size="small" onClick={() => reprove(v.id)}>
                {v.name}
              </Button>
            );
          })}
      </CardActions>
    </Card>
  );
}
