import React from 'react';
import {Dialog, DialogContent, DialogContentText} from '@mui/material';
import {ITheme} from "../interfaces";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    open: boolean;
    onClose: () => void;
    theme: ITheme
}

const Theme: React.FC<Props> = ({open, onClose, theme}) => {

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl">
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        {theme.motivationalTitle}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{minWidth: '900px'}}>
                <DialogContentText dangerouslySetInnerHTML={{__html: theme.motivationalText}}/>
            </DialogContent>
        </Dialog>
    );
};

export default Theme;
