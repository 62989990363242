import React, { RefObject, useEffect, useState } from "react";
import { Rect } from "react-konva";
import Konva from "konva";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Grid2 from "@mui/material/Unstable_Grid2";
import Correction from "./correction.component";
import CorrectionChecklist from "./correction-checklist.component";
import styles from "../correction.module.css";
import { ButtonGroup, Container, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { setRotation } from "../correction.slice";
import EssayImage from "../../../../app/components/essay-image.component";
import CommentPopover from "./comment-popover.component";

interface Rectangle {
  x: number;
  y: number;
  width: number;
  height: number;
}

type ImageCorrectionParams = {
  readonly?: boolean;
  onFinish: (next: boolean) => void;
};
const ImageCorrection: React.FC<ImageCorrectionParams> = (
  props: ImageCorrectionParams
) => {
  const correctionState = useAppSelector((state) => state.correction);
  const [rectangle, setRectangle] = useState<Rectangle>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [isDrawing, setIsDrawing] = useState(false);
  const [inCorrection, setInCorrection] = useState(false);
  const dispatch = useAppDispatch();

  const [currentSelection, setCurrentSelection] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  } | null>(null);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const [image] = useState(new window.Image());

  const startCorrection = () => {
    setInCorrection(true);
  };

  const handleMouseDown = (
    e: Konva.KonvaEventObject<MouseEvent>,
    stageRef: RefObject<Konva.Stage>
  ) => {
    if (!inCorrection) return;
    if (!props.readonly) {
      let pointerPosition = stageRef.current?.getPointerPosition();
      if (pointerPosition) {
        setRectangle({ ...pointerPosition, width: 0, height: 0 });
        setIsDrawing(true);
      }
    }
  };

  const handleMouseMove = (
    e: Konva.KonvaEventObject<MouseEvent>,
    stageRef: RefObject<Konva.Stage>
  ) => {
    if (isDrawing) {
      let pointerPosition = stageRef.current?.getPointerPosition();
      setRectangle((prevRect) => ({
        ...prevRect,
        width: pointerPosition ? pointerPosition?.x - prevRect.x : 0,
        height: pointerPosition ? pointerPosition?.y - prevRect.y : 0,
      }));
    }
  };

  const handleMouseUp = (
    e: Konva.KonvaEventObject<MouseEvent>,
    stageRef: RefObject<Konva.Stage>
  ) => {
    if (isDrawing) {
      const stage = stageRef.current;
      if (!stage) return;
      let left =
        rectangle.width >= 0 ? rectangle.x : rectangle.x + rectangle.width;
      let top =
        rectangle.height >= 0 ? rectangle.y : rectangle.y + rectangle.height;
      left = (left / stage.width()) * 100;
      top = (top / stage.height()) * 100;
      const width = (Math.abs(rectangle.width) / stage.width()) * 100;
      const height = (Math.abs(rectangle.height) / stage.height()) * 100;
      setCurrentSelection({ top, left, height, width });
      e.evt.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: e.evt.clientX + 2,
              mouseY: e.evt.clientY - 6,
            }
          : null
      );
    }
  };

  useEffect(() => {
    image.src = correctionState.essay?.url || "";
  }, [correctionState.essay?.url]);

  return (
    <Grid2 container>
      <Grid2 xs={8} className={styles.canvas}>
        <Grid2 xs={12}>
          <EssayImage
            image={image}
            rotation={correctionState.correction.rotation}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            drawing={
              isDrawing && (
                <Rect
                  x={rectangle.x}
                  y={rectangle.y}
                  width={rectangle.width}
                  height={rectangle.height}
                  stroke="black"
                />
              )
            }
          >
            {correctionState.comments.map((c, index) => {
              const criteria = correctionState.criteria.find(
                (criteria) => criteria.id === c.criteriaId
              );
              return (
                <Tooltip title={c.text}>
                  <span
                    key={index}
                    style={{
                      zIndex: 10,
                      position: "absolute",
                      left: `${c.left}%`,
                      top: `${c.top}%`,
                      height: `${c.height}%`,
                      width: `${c.width}%`,
                      border: `2px solid ${criteria?.color}`,
                      opacity: "0.3",
                      backgroundColor: criteria?.color,
                    }}
                  />
                </Tooltip>
              );
            })}
          </EssayImage>
          <CommentPopover
            currentSelection={currentSelection}
            contextMenu={contextMenu}
            onClose={() => {
              setContextMenu(null);
              setIsDrawing(false);
            }}
          />
        </Grid2>
      </Grid2>
      <Grid2 container direction="column" xs={4}>
        <Grid2
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <ButtonGroup variant="contained" sx={{ marginBottom: "1em" }}>
            <Tooltip title="Rotacionar sentido antihorário">
              <IconButton
                onClick={() =>
                  dispatch(
                    setRotation(
                      correctionState.correction.rotation === 0
                        ? 360 - 90
                        : correctionState.correction.rotation - 90
                    )
                  )
                }
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rotacionar sentido horário">
              <IconButton
                onClick={() =>
                  dispatch(
                    setRotation(
                      correctionState.correction.rotation === 270
                        ? 0
                        : correctionState.correction.rotation + 90
                    )
                  )
                }
              >
                <RotateRightIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
          {inCorrection || props.readonly ? (
            <Correction onFinish={props.onFinish} readonly={props.readonly} />
          ) : (
            <CorrectionChecklist startCorrection={startCorrection} />
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ImageCorrection;
