import { Box, Button, TextareaAutosize, Typography } from "@mui/material";
import { setFinalComment } from "../correction.slice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";

export default function FinalComment(props: {
  readonly?: boolean;
  errors: string[];
  enabled: boolean;
  onFinish: (next: boolean) => void;
}) {
  const correctionState: any = useAppSelector((state) => state.correction);
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [isReadyToFinish, setIsReadyToFinish] = useState(false);

  useEffect(() => {
    setIsReadyToFinish(true);
    let messages = [];
    if (
      correctionState.correction.finalComment &&
      correctionState.correction.finalComment.length < 300
    ) {
      setIsReadyToFinish(false);
      messages.push(
        `Tamanho mínimo do comentário final: ${correctionState.correction.finalComment.length}/300 caracteres.`
      );
    }
    setErrorMessage(messages);
  }, [correctionState.correction.finalComment]);

  return (
    <Box>
      <Typography variant="h6" component="h2">
        Comentário final
      </Typography>
      {props.readonly ? (
        <Box>{correctionState.correction.finalComment}</Box>
      ) : (
        <Box>
          <TextareaAutosize
            value={correctionState.correction.finalComment}
            onChange={(event) => {
              if (event.currentTarget.value.length > 0) {
                console.log(event.currentTarget.value);
                dispatch(setFinalComment(event.currentTarget.value));
              }
            }}
            minRows={6}
            placeholder="Digite seu comentário..."
          />
          {authState.authData?.user.role === "teacher" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                disabled={!isReadyToFinish || !props.enabled}
                sx={{ fontSize: "0.75em" }}
                color="primary"
                variant="outlined"
                onClick={() => {
                  props.onFinish(true);
                }}
              >
                Enviar e corrigir outra
              </Button>

              <Button
                disabled={!isReadyToFinish || !props.enabled}
                sx={{ marginLeft: "30px", fontSize: "0.75em" }}
                color="primary"
                variant="outlined"
                onClick={() => {
                  props.onFinish(false);
                }}
              >
                Enviar
              </Button>
            </Box>
          ) : (
            <Box>
              <Button
                disabled={!isReadyToFinish || !props.enabled}
                sx={{ marginLeft: "30px" }}
                color="primary"
                variant="outlined"
                onClick={() => {
                  props.onFinish(false);
                }}
              >
                Salvar
              </Button>
            </Box>
          )}
          <Box>
            {[...props.errors, ...errorMessage].map((e, key) => (
              <Box>
                <Typography key={key} align="left" color="darkred">
                  - {e}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
