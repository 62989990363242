import React from "react";
import { Outlet } from "react-router-dom";
import TeacherMenu from "./teacher-menu.component";

function TeacherApp() {
  return (
    <div id="detail">
      <TeacherMenu />
      <Outlet />
    </div>
  );
}

export default TeacherApp;
