import React from "react";
import {createRoot} from "react-dom/client";
import {store} from "./app/store";
import reportWebVitals from "./reportWebVitals";
import {RouterProvider} from "react-router-dom";
import mainRoutes from "./app/route";
import {Provider} from "react-redux";
import {CssBaseline} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {ptBR} from '@mui/material/locale';

const container = document.getElementById("root")!;
const root = createRoot(container);
const theme = createTheme({
    palette: {
        primary: {
            main: "#0059FA",
        },
        secondary: {
            main: "#FF8200",
            contrastText: "#fff",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                textarea {
                  padding: 10px;
                  width: 100%
                }
              `,
        },
    }
}, ptBR);
root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme/>
            <RouterProvider router={mainRoutes}/>
        </ThemeProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
