import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import correctionService from "../correction/correction.service";
import {IQueuesState} from "../../../app/interfaces";


export const getQueues = createAsyncThunk(
    "corrections/queues",
    async () => {
        return correctionService.getQueues();
    }
);
export const getNextCorrection = createAsyncThunk(
    "corrections/next",
    async (examId: number) => {
        return correctionService.getNextCorrection(examId);
    }
)

const initialState: IQueuesState = {queues: []};

const correctionQueuesSlice = createSlice({
    name: "correction-queues",
    initialState,
    reducers: {
        resetSelectedCorrection: (state, action: PayloadAction<void>) => {
            state.currentCorrectionId = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getQueues.fulfilled, (state, {payload}) => {
            state.queues = payload;
        });
        builder.addCase(getQueues.rejected, (state) => {
            state.queues = [];
        });
        builder.addCase(getNextCorrection.fulfilled, (state, {payload}) => {
            state.currentCorrectionId = payload;
        });
        builder.addCase(getNextCorrection.rejected, (state) => {
            state.currentCorrectionId = undefined;
        });
    },
});

export const {
    resetSelectedCorrection
} = correctionQueuesSlice.actions;
export default correctionQueuesSlice.reducer;

