import axios from 'axios';

const apiAprova = axios.create({
    baseURL: process.env.REACT_APP_API_APROVA_BASE_URL,
});

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(async (config: any) => {
    const url = config.url;
    let item = localStorage.getItem("user");
    const userToken = item ? JSON.parse(item).access_token : null;
    config.headers.Accept = 'application/json';

    if (url.endsWith('login') || url.endsWith('refresh') || url.endsWith('signup') || url.includes('oapi')) {
        return config;
    }
    config.headers.Authorization = 'bearer ' + userToken;
    return config;

}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(async (response: any) => {
    return response;
}, (error) => {
    const response = error && error.response ? error.response : undefined;
    if (response && response.status) {
        if (response.status === 401) {
            localStorage.clear()
            window.location.href = '/';
        }
    }
    return Promise.reject(error);
});

apiAprova.interceptors.request.use(async (config: any) => {
    config.headers.Accept = 'application/json';
    return config;

}, (error) => {
    return Promise.reject(error);
});

export {api, apiAprova};
