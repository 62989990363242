import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getQueues, resetSelectedCorrection } from "./correction-queues.slice";
import { useNavigate } from "react-router-dom";
import QueueCard from "./queue-card.component";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

function CorrectionQueues() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const queuesState = useAppSelector((state) => state.queues);

  useEffect(() => {
    if (queuesState.queues.length === 0) {
      dispatch(getQueues());
    }
  }, []);

  if (queuesState.currentCorrectionId) {
    dispatch(resetSelectedCorrection());
    navigate(`/teacher/correction/${queuesState.currentCorrectionId}`);
  }
  return (
    <Box>
      <Grid2
        container
        direction="column"
        spacing={4}
        justifyContent="left"
        marginTop="0.75em"
        style={{ maxWidth: "90%", height: "100%", marginLeft: "5%" }}
      >
        <Grid2 xs={12} display="flex">
          <Typography variant="h4" mb={1}>
            Redações disponíveis:
          </Typography>
        </Grid2>
        <Grid2
          sx={{ boxShadow: 1, borderRadius: "10px", marginBottom: "1em" }}
          container
          rowSpacing={2}
          spacing={2}
          justifyContent="left"
          style={{ backgroundColor: "#efefef" }}
        >
          {queuesState.queues.length ? (
            queuesState.queues.map((queue, index) => (
              <QueueCard
                key={index}
                available={queue.total}
                commentType={"manual"}
                exam={queue.exam}
                examId={queue.examId}
                price={queue.price}
              />
            ))
          ) : (
            <Typography variant="h5">Nenhuma redação disponível</Typography>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default CorrectionQueues;
