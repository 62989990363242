import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea, CardHeader} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {getNextCorrection} from "./correction-queues.slice";
import {useAppDispatch} from "../../../app/hooks";

export interface IQueueCardProps {
    exam: string
    examId: number
    commentType: 'manual' | 'predefined'
    price: number
    available: number
}

export default function QueueCard(props: IQueueCardProps) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    return (
        <Card sx={{minWidth: 275, textAlign: 'center', margin: '50px'}}>
            <CardActionArea onClick={() => dispatch(getNextCorrection(props.examId))}>
                <CardHeader title={props.exam}
                            color="secondary"
                            subheader={props.commentType === 'manual' ? 'Comentários Manuais' : 'Comentários Predefinidos'}
                            style={{backgroundColor: theme.palette.secondary.main, color: 'white'}}>
                </CardHeader>
                <CardContent>
                    <Typography variant="h2" component="div" fontWeight="600">
                        {props.available}
                    </Typography>
                    <Typography sx={{mb: 1.5}} color="text.primary">
                        redações disponíveis
                    </Typography>
                    {/*<Typography sx={{mb: 1.5}} color="text.secondary">*/}
                    {/*    {currency(props.price)} / correção*/}
                    {/*</Typography>*/}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
