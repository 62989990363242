import Login from "../features/auth/login.component";
import {createBrowserRouter,} from "react-router-dom";
import TeacherApp from "../features/teacher/teacher-app.component";
import CorrectionQueues from "../features/teacher/queues/correction-queues.component";
import React from "react";
import BaseCorrection from "../features/teacher/correction/base-correction.component";
import ModeratorApp from "../features/moderator/moderator-app.component";
import Moderation from "../features/moderator/moderation/moderation.component";
import Feedback from "../features/moderator/feedback/feedback.component";
import Wallet from "../features/teacher/wallet/wallet.component";

const mainRoutes = createBrowserRouter([
    {
        path: "/",
        element: <Login></Login>,
    },
    {
        path: "/teacher",
        element: <TeacherApp></TeacherApp>,
        children: [
            {
                path: "dashboard",
                element: <CorrectionQueues></CorrectionQueues>,
            },
            {
                path: "queues",
                element: <CorrectionQueues></CorrectionQueues>,
            },
            {
                path: "correction/:id",
                element: <BaseCorrection></BaseCorrection>,
            },
            {
                path: "wallet",
                element: <Wallet></Wallet>,
            },
        ],
    },
    {
        path: "/moderator",
        element: <ModeratorApp></ModeratorApp>,
        children: [
            {
                path: "moderation",
                element: <Moderation></Moderation>,
            },
            {
                path: "feedback",
                element: <Feedback></Feedback>,
            },
        ],
    },
]);

export default mainRoutes;
