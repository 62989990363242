import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { Box, ButtonGroup, IconButton, TextareaAutosize } from "@mui/material";
import { IComment } from "../../../../app/interfaces";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { removeComment, updateComment } from "../correction.slice";
import { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import ThumbUpBorder from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUp from "@mui/icons-material/ThumbUp";

export default function CorrectionComment(props: {
  readonly?: boolean;
  comment: IComment;
}) {
  const correctionState = useAppSelector((state) => state.correction);
  const criteria = correctionState.criteria.find(
    (c) => c.id === props.comment.criteriaId
  );
  const dispatch = useAppDispatch();
  const [isEditMode, setEditMode] = useState(false);
  const [comment, setComment] = useState({ ...props.comment });
  const [isPositive, setPositive] = useState(props.comment.isPositive);
  const edit = () => {
    setEditMode(true);
  };

  useEffect(() => setComment(props.comment), [props.comment]);

  const save = () => {
    dispatch(updateComment({ ...comment, isPositive })).then(() =>
      setEditMode(false)
    );
  };
  const remove = () => {
    dispatch(removeComment(comment));
  };

  useEffect(() => {
    if (comment.isPositive !== isPositive) {
      save();
    }
  }, [comment.isPositive, isPositive]);

  if (!isEditMode) {
    return (
      <Box
        sx={{
          backgroundColor: criteria?.color,
          padding: "10px",
          fontSize: "0.75rem",
          marginBottom: "0.75em",
        }}
      >
        <Box style={{ fontWeight: 700, marginBottom: "0.5em" }}>
          {criteria?.name}
          {props.readonly || (
            <>
              <Checkbox
                color="success"
                defaultChecked={props.comment.isPositive}
                icon={<ThumbUpBorder />}
                checkedIcon={<ThumbUp />}
                sx={{ float: "right" }}
                onChange={() => {
                  setPositive(!isPositive);
                }}
              />
              <ButtonGroup
                disableElevation
                variant="contained"
                aria-label="Disabled elevation buttons"
                sx={{ float: "right" }}
              >
                <IconButton onClick={edit}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton onClick={remove}>
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </ButtonGroup>
            </>
          )}
        </Box>
        <Box style={{ fontStyle: "italic" }}>{props.comment.text}</Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: criteria?.color,
          padding: "10px",
          fontSize: "0.75rem",
          marginBottom: "0.5em",
        }}
      >
        <Box style={{ fontWeight: 700, marginBottom: "8px" }}>
          {criteria?.name}
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            sx={{ float: "right" }}
          >
            <IconButton onClick={save} disabled={!comment.text.length}>
              <SaveOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => setEditMode(false)}>
              <CancelOutlinedIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
        <Box>
          <TextareaAutosize
            defaultValue={comment.text}
            onChange={(event) =>
              setComment({
                ...comment,
                text: event.currentTarget.value.toString(),
              })
            }
            minRows={5}
          />
        </Box>
      </Box>
    );
  }
}
