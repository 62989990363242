import React, {useEffect} from "react";
import {currency} from "../../../utils";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getBalance} from "./wallet.slice";

export default function CurrentBalance() {
    const dispatch = useAppDispatch();
    const walletState = useAppSelector((state) => state.wallet);

    useEffect(() => {
        dispatch(getBalance())
    }, []);

    return (
        <div style={{margin: "25px"}}>
            Saldo atual: {currency(walletState.balance)}
        </div>
    );
}
