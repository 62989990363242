import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    Modal,
    OutlinedInput,
    Typography
} from '@mui/material';
import {useAppDispatch} from "../../app/hooks";
import {useTheme} from "@mui/material/styles";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {AxiosResponse} from "axios";
import userService from "./user.service";

interface Props {
    open: boolean;
    onClose: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ChangePasswordModal: React.FC<Props> = ({open, onClose}) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const theme = useTheme();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const currentPassword = formData.get('password') as string;
        const newPassword = formData.get('newPassword') as string;
        const confirmPassword = formData.get('confirmPassword');

        if (newPassword === confirmPassword) {
            setError('');
            dispatch(userService.changePassword(currentPassword, newPassword)).then((res: AxiosResponse) => {
                if (res.status === 200) {
                    onClose();
                } else {
                    setError('Senha atual inválida');
                }
            });
        } else {
            setError('As senhas não correspondem');
        }
    };

    return (

        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Senha atual</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="password"
                            type={showCurrentPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Nova senha</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="newPassword"
                            type={showNewPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal" variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Confirmar nova senha</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            name="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                        edge="end"
                                    >
                                        {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <Typography color="error">{error}</Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color='secondary'
                        sx={{mt: 3, mb: 2, fontWeight: 900}}
                    >
                        Alterar Senha
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;
