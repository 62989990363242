import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import WalletService from "./wallet.service";
import {ITransaction} from "../../../app/interfaces";


export interface WalletState {
    balance: number
    transactions: ITransaction[]
    error?: string
}

export const getBalance = createAsyncThunk(
    "transactions/balance",
    async () => {
        return await WalletService.balance();
    }
);

export const getTransactions = createAsyncThunk(
    "transactions/getTransactions",
    async () => {
        return await WalletService.getTransactions();
    }
);

const initialState: WalletState = {balance: 0, transactions: []};

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBalance.fulfilled, (state, {payload}) => {
            state.balance = payload;
        });
        builder.addCase(getBalance.rejected, (state) => {
            state.error = "Erro ao buscar saldo";
        });
        builder.addCase(getTransactions.fulfilled, (state, {payload}) => {
            state.transactions = payload;
        });
        builder.addCase(getTransactions.rejected, (state) => {
            state.error = "Erro ao buscar transações";
        });
    },
});

export default walletSlice.reducer;

