import * as React from "react";
import { useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getEssayCorrections } from "../moderator.slice";
import { ICorrection } from "../../../app/interfaces";
import { getTheme } from "../../teacher/correction/correction.slice";
import Theme from "../../../app/components/theme.component";
import CorrectionTable from "../../../components/moderator/correction-table/correction-table.component";
import ExamHeader from "../../../components/moderator/exam-header/exam-header.component";

export default function FeedbackEssayDetail(props: {
  onCorrectionSelected: (id: number) => void;
}) {
  const dispatch = useAppDispatch();
  const moderationState = useAppSelector((state) => state.moderation);
  const correctionState = useAppSelector((state) => state.correction);
  const [selectedCorrection, setSelectedCorrection] =
    useState<ICorrection | null>();
  const [showTheme, setShowTheme] = useState(false);
  const [image] = useState(new window.Image());

  useEffect(() => {
    setSelectedCorrection(undefined);
    if (moderationState.selectedEssay) {
      dispatch(getEssayCorrections(moderationState.selectedEssay.id));
      image.src = moderationState.selectedEssay.url || "";
      dispatch(getTheme(moderationState.selectedEssay.themeId));
    }
  }, [moderationState.selectedEssay]);

  useEffect(() => {
    const correction = moderationState.selectedEssayCorrections.find(
      (c: ICorrection) =>
        c.id === moderationState.selectedEssay?.approvedCorrectionId
    );
    setSelectedCorrection(correction);
  }, [moderationState.selectedEssayCorrections.length]);

  useEffect(() => {
    if (selectedCorrection?.id) {
      props.onCorrectionSelected(selectedCorrection.id);
    }
  }, [selectedCorrection]);

  return (
    <Grid2 container>
      <Grid2 xs={12} margin={3}>
        <Theme
          open={showTheme}
          onClose={() => setShowTheme(false)}
          theme={correctionState.theme}
        />
        <ExamHeader
          theme={correctionState.theme.motivationalTitle}
          exam={correctionState.exam.name || ""}
          setSelectedCorrection={(status) => setSelectedCorrection(status)}
          showModal={(show) => setShowTheme(show)}
          origin="Feedback"
        />
        <CorrectionTable
          data={moderationState.selectedEssayCorrections}
          selectedId={selectedCorrection?.id || 0}
          setSelected={(correction) => setSelectedCorrection(correction)}
          origin="Feedback"
          approvedId={moderationState.selectedEssay?.approvedCorrectionId}
        />
      </Grid2>
    </Grid2>
  );
}
