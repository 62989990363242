import { Box, Button, Container } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AppBar from "@mui/material/AppBar";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  newCorrection,
  finishEdit,
  getEssaysPendingModeration,
  getFeedbacks,
} from "../../../features/moderator/moderator.slice";
import { reset } from "../../../features/teacher/correction/correction.slice";

interface IExamHeader {
  theme: string;
  exam: string;
  origin: string;
  showModal: (show: boolean) => void;
  setSelectedCorrection: (status: undefined) => void;
}

const ExamHeader = (props: IExamHeader) => {
  const { theme, exam, origin, showModal, setSelectedCorrection } = props;
  const dispatch = useAppDispatch();
  const moderationState = useAppSelector((state) => state.moderation);

  const disapprove = () => {
    if (moderationState.selectedEssay?.id) {
      dispatch(newCorrection(moderationState.selectedEssay.id)).then(() => {
        dispatch(reset());
        dispatch(finishEdit());
        setSelectedCorrection(undefined);
        dispatch(
          origin === "Moderation"
            ? getEssaysPendingModeration()
            : getFeedbacks()
        );
      });
    }
  };

  return (
    <AppBar position="static" color="default">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#333",
                display: "block",
                flexGrow: 1,
              }}
            >
              <b>Exame</b>: {exam}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#333",
                display: "block",
                flexGrow: 1,
                marginLeft: "1em",
              }}
            >
              <b>Tema</b>: {theme}
            </Typography>
            <Button
              sx={{ marginLeft: "50px" }}
              onClick={() => showModal(true)}
              variant="outlined"
              color="primary"
            >
              <TextSnippetIcon /> Ver Proposta
            </Button>
            <Button
              sx={{ marginLeft: "1em" }}
              onClick={disapprove}
              variant="outlined"
              color="primary"
            >
              <NoteAddIcon /> Nova Correção
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ExamHeader;
