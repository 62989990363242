import * as React from "react";
import {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/images/logo.svg";
import {Divider, Link} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import CurrentBalance from "./wallet/balance.component";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChangePasswordModal from "../auth/change-password.component";
import {KeyboardArrowDownOutlined,} from "@mui/icons-material";

interface IAppBarMenu {
    label: string;
    url: string;
}

function TeacherMenu() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );
    const correctionState = useAppSelector((state) => state.correction);
    const [pages, setPages] = useState<IAppBarMenu[]>([]);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const userStringfied = localStorage.getItem("user") || "";
    const user = JSON.parse(userStringfied).user;

    useEffect(() => {
        if (correctionState.correction.id) {
            setPages([
                {label: "Dashboard", url: "/teacher/dashboard"},
                {
                    label: "Correção",
                    url: `/teacher/correction/${correctionState.correction.id}`,
                },
                {label: 'Financeiro', url: '/teacher/wallet'}
            ]);
        } else {
            setPages([
                {label: "Dashboard", url: "/teacher/dashboard"},
                {label: 'Financeiro', url: '/teacher/wallet'}
            ]);
        }
    }, [correctionState.correction]);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const exit = () => {
        localStorage.clear();
        window.location.href = "/";
    };

    const changePassword = () => {
        setShowChangePassword(true);
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img
                        src={logo}
                        height="80"
                        width="150"
                        style={{marginRight: "100px"}}
                    />

                    <Box sx={{flexGrow: 1, display: "flex"}}>
                        {pages.map((page, index) => (
                            <Link href={page.url} key={index}>
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: "block",
                                        marginLeft: "50px",
                                    }}
                                >
                                    {page.label}
                                </Button>
                            </Link>
                        ))}
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <CurrentBalance/>
                    </Box>
                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{background: "#D9D9D9"}}
                    />
                    <Box sx={{flexGrow: 0, marginLeft: "11px"}}>
                        <IconButton
                            onClick={handleOpenUserMenu}
                            sx={{p: 0, color: "white", borderRadius: 0}}
                        >
                            <AccountCircleIcon sx={{fontSize: 40}}></AccountCircleIcon>
                            <Box sx={{marginLeft: "7px", marginRight: "25px"}}>
                                <Typography
                                    align="left"
                                    sx={{fontSize: "0.875rem", fontWeight: 700}}
                                >
                                    {user.name}
                                </Typography>
                                <Typography align="left" sx={{fontSize: "0.75rem"}}>
                                    {user.email}
                                </Typography>
                            </Box>
                            <KeyboardArrowDownOutlined/>
                        </IconButton>
                        <Menu
                            sx={{mt: "45px"}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={changePassword}>
                                <Typography textAlign="center">Alterar Senha</Typography>
                            </MenuItem>
                            <MenuItem onClick={exit}>
                                <Typography textAlign="center">Sair</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            <ChangePasswordModal
                open={showChangePassword}
                onClose={() => setShowChangePassword(false)}
            />
        </AppBar>
    );
}

export default TeacherMenu;
