import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import AuthService from "./user.service";
import {IUser} from "../../app/interfaces";

let item = localStorage.getItem("user");
const authData: IAuthData = item ? JSON.parse(item) : null

interface ICredentials {
    username: string,
    password: string
}

export interface IAuthData {
    access_token: string
    user: IUser
}

export interface AuthState {
    authData: IAuthData | null
    isLoggedIn: boolean
}

export const login = createAsyncThunk(
    "auth/login",
    async (authData: ICredentials, thunkAPI) => {
        try {
            const data = await AuthService.login(authData.username, authData.password);
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(null);
        }
    }
);

const initialState: AuthState = authData
    ? {isLoggedIn: true, authData}
    : {isLoggedIn: false, authData: null};

const userSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            localStorage.removeItem("token");
            state.isLoggedIn = false;
            state.authData = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, {payload}) => {
            state.isLoggedIn = true;
            state.authData = payload;
        });
        builder.addCase(login.rejected, (state) => {
            state.isLoggedIn = false;
            state.authData = null;
        })
    },
});

export default userSlice.reducer;

