import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";

type tableData = {
  name: string;
  id: number;
  total: number;
  positive: number;
  negative: number;
};

export interface ITable {
  data: tableData[] | undefined;
}

const CommentTable = (props: ITable) => {
  const { data } = props;

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: "100%", margin: "auto" }}
        size="small"
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Competência</TableCell>
            <TableCell>
              <ThumbUpOutlined fontSize="small" />
            </TableCell>
            <TableCell>
              <ThumbDownOutlined fontSize="small" />
            </TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((competence) => (
            <TableRow
              key={competence.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
            >
              <TableCell>{competence.name}</TableCell>
              <TableCell>{competence.positive}</TableCell>
              <TableCell>{competence.negative}</TableCell>
              <TableCell>{competence.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommentTable;
