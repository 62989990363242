import React from "react";
import { Outlet } from "react-router-dom";
import ModeratorMenu from "./moderator-menu.component";

function ModeratorApp() {
  return (
    <div id="detail">
      <ModeratorMenu />
      <Outlet />
    </div>
  );
}

export default ModeratorApp;
