import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { clearError, reset } from "../../teacher/correction/correction.slice";
import Grid2 from "@mui/material/Unstable_Grid2";
import FeedbackEssayList from "./essay-list.component";
import FeedbackEssayDetail from "./essay-detail.component";
import BaseCorrection from "../../teacher/correction/base-correction.component";
import {
  approveCorrection,
  cleanCriteriaValues,
  finishEdit,
  getFeedbacks,
} from "../moderator.slice";

export default function Feedback() {
  const moderationState = useAppSelector((state) => state.moderation);
  const dispatch = useAppDispatch();
  const [selectedCorrection, setSelectedCorrection] = useState<number>();

  useEffect(() => {
    if (moderationState.error) {
      const swal = withReactContent(Swal);
      swal
        .fire({
          title: "Ops...",
          text: moderationState.error,
          icon: "error",
          confirmButtonText: "Ok",
        })
        .then(() => dispatch(clearError()));
    }
  }, [moderationState.error]);

  return (
    <Grid2 container margin={3} sx={{ height: "100%" }}>
      <Grid2 xs={3} sx={{ boxShadow: 1 }}>
        <FeedbackEssayList />
      </Grid2>
      <Grid2 xs={9}>
        {moderationState.selectedEssay && (
          <FeedbackEssayDetail onCorrectionSelected={setSelectedCorrection} />
        )}
      </Grid2>
      <Grid2 xs={12} margin={1}>
        {selectedCorrection && (
          <BaseCorrection
            id={selectedCorrection}
            origin="Feedback"
            readonly={true}
            onFinish={() => {
              dispatch(finishEdit());
              dispatch(reset());
              dispatch(approveCorrection(selectedCorrection));
              dispatch(getFeedbacks());
              dispatch(cleanCriteriaValues());
            }}
          />
        )}
      </Grid2>
    </Grid2>
  );
}
