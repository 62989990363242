import { useAppSelector } from "../../../../app/hooks";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import CorrectionComment from "./correction-comment.component";
import FinalComment from "./final-comment.component";
import CorrectionScore from "./correction-score.component";
import CommentTable from "./comment-table.component";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      style={{ margin: "10px" }}
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type tableData = {
  name: string;
  id: number;
  total: number;
  positive: number;
  negative: number;
};

export default function Correction(props: {
  readonly?: boolean;
  onFinish: (next: boolean) => void;
}) {
  const correctionState = useAppSelector((state) => state.correction);

  const [value, setValue] = useState(0);
  const [valid, setValid] = useState(false);
  const [isReadyToFinish, setIsReadyToFinish] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [reportData, setReportData] = useState<tableData[]>();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    mountReport();
  }, [correctionState]);

  const mountReport = () => {
    const criterias = correctionState.criteria;
    const comments = correctionState.comments;
    let newArray: tableData[] = [];

    for (let criteria of criterias) {
      const commentsOf = comments.filter(
        (comment) => comment.criteriaId === criteria.id
      );
      const commentsPositive = commentsOf.filter(
        (comment) => comment.isPositive
      ).length;
      const commentsNegative = commentsOf.filter(
        (comment) => !comment.isPositive
      ).length;

      const tableObj: tableData = {
        total: commentsOf.length,
        positive: commentsPositive,
        negative: commentsNegative,
        name: criteria.name,
        id: criteria.id,
      };

      newArray.push(tableObj);
    }

    setReportData(newArray);
  };

  useEffect(() => {
    const isValid = correctionState.validity.find(
      (v) => v.id === correctionState.correction.correctionValidityId
    )?.isValid;
    setValid(isValid || false);
  }, [correctionState.correction.correctionValidityId]);

  useEffect(() => {
    setIsReadyToFinish(true);
    let messages = [];
    if (
      !correctionState.correction.correctionValidityId ||
      (valid &&
        correctionState.correction.criteriaValues.length !==
          correctionState.criteria.length)
    ) {
      setIsReadyToFinish(false);
      messages.push("Precisa selecionar o Status da redação");
    }
    if (
      correctionState.criteria.length >
        new Set(correctionState.comments.map((c) => c.criteriaId)).size &&
      valid
    ) {
      setIsReadyToFinish(false);
      messages.push("Precisa adicionar ao menos 1 comentário de cada critério");
    }
    setErrorMessage(messages);
  }, [
    correctionState.correction.correctionValidityId,
    valid,
    correctionState.correction.criteriaValues.length,
    correctionState.criteria,
    correctionState.comments,
  ]);

  return (
    <Container sx={{ position: "flex", width: "95%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Comentários" {...a11yProps(0)} />
          <Tab label="Nota" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {correctionState.comments.length
          ? correctionState.comments.map((comment, index) => {
              return (
                <>
                  <CorrectionComment
                    key={index}
                    comment={comment}
                    readonly={props.readonly}
                  />
                </>
              );
            })
          : "Nenhum comentário adicionado ..."}
        <Typography mt={2.5} mb={1}>
          Resumo
        </Typography>
        <CommentTable data={reportData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CorrectionScore readonly={props.readonly} />
        <FinalComment
          readonly={props.readonly}
          enabled={isReadyToFinish}
          onFinish={props.onFinish}
          errors={errorMessage}
        />
      </TabPanel>
    </Container>
  );
}
