import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {format} from "date-fns";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {clearError} from "../correction/correction.slice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {ITransaction} from "../../../app/interfaces";
import {getTransactions} from "./wallet.slice";
import {currency} from "../../../utils";
import Grid2 from "@mui/material/Unstable_Grid2";

const Wallet: React.FC = () => {
    const [filterDate, setFilterDate] = useState<string>(format(new Date(), "yyyy-MM"));
    const [totalValue, setTotalValue] = useState<number>(0);
    const [filteredTransactions, setFilteredTransactions] = useState<ITransaction[]>([]);
    const [selectedTransaction, setSelectedTransaction] = useState<ITransaction | null>(null);
    const walletState = useAppSelector((state) => state.wallet);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getTransactions());
    }, [])
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = event.target.value;
        setFilterDate(date);
    };

    const handleTransactionClick = (transaction: ITransaction) => {
        setSelectedTransaction(transaction);
    };

    const handleTransactionClose = () => {
        setSelectedTransaction(null);
    };

    useEffect(() => {
        const filtered = walletState.transactions.filter(
            (transaction) => monthYearFormatter(transaction.date) === filterDate
        );
        setFilteredTransactions(filtered);
    }, [walletState.transactions, filterDate]);

    const dateFormatter = (date?: Date) => {
        if (date) {
            return format(new Date(date), 'dd/MM/yyyy HH:mm');
        }
        return ' - ';
    };

    const monthYearFormatter = (date: Date) => {
        return format(new Date(date), 'yyyy-MM');
    };

    useEffect(() => {
        if (walletState.error) {
            const swal = withReactContent(Swal);
            swal
                .fire({
                    title: "Ops...",
                    text: walletState.error,
                    icon: "error",
                    confirmButtonText: "Ok",
                })
                .then(() => dispatch(clearError()));
        }
    }, [walletState.error]);

    useEffect(() => {
        console.log(filteredTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount.toString()), 0))
        setTotalValue(filteredTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount.toString()), 0));
    }, [filteredTransactions])


    return (
        <Grid2 container sx={{height: "100%", maxWidth: '800px', margin: 'auto', paddingTop: '30px'}}>
            <TextField
                id="date"
                label="Filtro por mês"
                type="month"
                value={filterDate}
                onChange={handleFilterChange}
                sx={{my: 2, marginLeft: 'auto'}}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell sx={{align: 'left'}}>Descrição</TableCell>
                            <TableCell>Valor</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredTransactions.map((transaction, index) => (
                            <TableRow key={index}>
                                <TableCell>{dateFormatter(transaction.date)}</TableCell>
                                <TableCell sx={{align: 'left'}}>
                                    <a href="#" onClick={() => handleTransactionClick(transaction)}>
                                        {transaction.description}
                                    </a>
                                </TableCell>
                                <TableCell>{currency(transaction.amount)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2} align="right">
                                Total:
                            </TableCell>
                            <TableCell>{currency(totalValue)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={!!selectedTransaction} onClose={handleTransactionClose}>
                <DialogTitle>Detalhes da transação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedTransaction?.description}
                    </DialogContentText>
                    <DialogContentText>
                        Data: {dateFormatter(selectedTransaction?.date)}
                    </DialogContentText>
                    <DialogContentText>
                        Valor: {currency(selectedTransaction?.amount)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTransactionClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    );
};

export default Wallet;
